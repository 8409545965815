const CodepenSVG = () => (
  <svg width="24" height="24" viewBox="0 0 64 64" fill="none" stroke="#000000" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round">
    <path
      d="M3.06 41.732L32 60.932l28.94-19.2V22.268L32 3.068l-28.94 19.2zm57.878 0L32 22.268 3.06 41.732m0-19.463L32 41.47l28.94-19.2M32 3.068v19.2m0 19.463v19.2"
      strokeWidth="6.05"
    />
  </svg>
);

export default CodepenSVG;
